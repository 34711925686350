.bg_elgli {
  background-image: url("../../assests/images/background/elgibility.jpg");
  height: 60vh;
  max-width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: grid;
  place-items: center;
  justify-content: center;
}


.caard {
  box-shadow: 0px 0px 2px 2px #eee;
  border-top: 1px solid transparent;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  border-bottom: 0px solid transparent;
  border-radius: 15px;
  transition: all 0.5s;
  height: 460px;
  padding: 15px;
  width: 100%;
}
.caard:hover {
  border-bottom: 1px solid #e77000;
  border-top: 1px solid #e77000;
  border-left: 1px solid #e77000;
  border-right: 1px solid #e77000;
  transform: translateY(-20px);
}






















@media screen and (max-width: 768px) {
  .eli {
    font-size: 25px !important;
  }
  .bg_elgli {
    height: 219px !important;
  }
  .elii {
    font-size: 15px !important;
  }
  .we {
    font-size: 21px !important;
  }
  .cruise {
    margin-top: -48% !important;
  }
  .commer {
    margin-top: 10% !important;
  }
  .req {
    margin-top: -18% !important;
  }
  .crew {
    margin-top: -30% !important;
  }
  .off {
    margin-top: 10% !important;
  }

  .pump {
    margin-top: 7% !important;
  }
  .cap {
    margin-top: -20% !important;
  }
  .seaf {
    margin-top: -22% !important;
  }
}
.video-react-has-started .video-react-control-bar {
  display: none !important;
}
.elgi_documents {
  position: absolute;
  margin-top: -45pc;
  left: 3pc;
  z-index: 999;
  color: #ffffff;
  background: #22222257;
  border-radius: 8px;
  /* padding: 5px; */
  font-weight: 700;
  width: fit-content;
}
.selected_tab {
  background: #00d4ff;
  border-radius: 8px;
}
.border-right {
  border-right: 2px solid #222;
}
.border_bottom {
  border-bottom: 2px solid;
}
.br-8 {
  border-radius: 8px;
}
