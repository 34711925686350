.footer {
  background-color: #222;

  /* z-index:-1; */
}
.social_icon:hover {
  color: #e77000 !important;
  transform: translateY(-20px);
}
.social_icon {
  transition: 0.5s all;
  padding: 6px;
  /* cursor: pointer; */
}
.footerlinks {
  color: #ffffff;
  text-decoration: none;
  font-size: 14px !important;
  padding:2px ;
  margin-top: 4px;
}


.footerlinks:hover {
  color: #ffffff;
}
.quick{
  margin-bottom: 50px;
  
}
.butt{
  margin-top: 7px;
  
}
.logo{
  margin-bottom: 16px;
}
.copy a {
  color:  #e77000;
  text-decoration: none;
}
.copy {
  font-size: 14px !important;
}
.copy a:hover {
  color: #e77000;
}

