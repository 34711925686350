.bg_about{
    background-image: url('../../assests/images/background/about.jpg');
    height: 75vh;
    max-width:100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: grid;
    place-items: center;
    justify-content: center;
   
}
/* .about_us{
    background-image: url('../../assests/images/background/about-us.png');
    height: 100vh;
    max-width:100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: grid;
    place-items: center;
    justify-content: center; */
    
   

 /* .about_bannerdesc{
    font-size: 77px;
} */
/* .text-center{
    color: #e77007;
    font-size: 20px;
    letter-spacing:10px ;
} */
/* .text-white{
    margin-top: 40%;
    letter-spacing: 5px;
}  */

.ourmission{
font-size:larger;
font-weight: bold;
}

.trip{
    display: grid;
    place-items: center;
    justify-content:center;
    color: white;
    font-size: 100px;
    
}
.together{
    font-size: 90px;
    text-align: end;

  
}
.contact{
    font-size: 25px;
     text-align: start;   
}



.card {
    box-shadow: 0px 0px 2px 2px #eee;
    border-top: 1px solid transparent;
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
    border-bottom: 5px solid transparent;
    border-radius: 8px;
    transition: all 0.5s;
    height: 250px;
    padding: 10px;
    width: 90%;
  }
  .card:hover {
    border-bottom: 1px solid #e77000;
  border-top: 1px solid #e77000;
  border-left: 1px solid #e77000;
  border-right: 1px solid #e77000;
  transform: translateY(-20px);
  }
  
  .aboutdesc{
   color: black;
    box-shadow: 0px 0px 2px 2px #eee;
    border-top: 0px solid transparent;
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
    border-bottom: 5px solid transparent;
    border-radius: 8px;
    transition: all 0.5s;
    padding: 10px;
  }
  


  @media screen and (max-width: 768px) {
    .bg_about {
      height: 200px !important;
   }
   .abo{font-size: x-large !important;
margin-top: 10% !important;
}
   .sail{font-size: 140% !important;}
 .desr{margin-top: -45% !important;}
 .blog{margin-top: 1% !important;}
 .trav{font-size: x-large !important;}
 .sche{margin-top: -1px !important;}
 .sched{margin-bottom: 10% !important;}
 .me{margin-top: -15% !important;}
 .aksh{margin-bottom:10% !important;
 margin-top: -25% !important;}
 .swa{margin-bottom:10% !important;}
}
@media screen and (min-width: 1280px) and (min-height:800px) {

}