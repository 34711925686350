.featurecard {
  box-shadow: 0px 0px 1px 1px #eee;
  border: 1px solid #eee;
}
.feature_btn {
  border-bottom: 1px solid #222;
}
.feature_btn:hover {
  border-bottom: 1px solid #e77000;
  color: #e77000 !important;
  background-image: url("../../assests/images/background/parallax_image1.jpg") !important;
  background-position: center;
  height: 300px;
  background-repeat: no-repeat;
  background-size: cover;
}
.featurecard:hover {
  border: 1px solid #e77000;
}
