/* .bg_home {
  background-image: url("../../assests/images/background/strip.jpg");
  height: 40vh;
  max-width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: grid;
  place-items: center;
  align-items: center;
} */

.parallax_feature {
  position: relative;
  overflow: hidden;
  background-image: url("../../assests/images/background/parallax_image1.jpg");
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}
.popular_section {
  background-color: white;
}
.rounded_image {
  border: 5px solid #ffffff;
  box-shadow: 0px 0px 4px 4px #eee;
}
.rounded_image:hover {
  border: 5px solid #e77000;
}
.contactus_parallax3 {
  background-image: url("../../assests/images/background/parallax_feature1.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  height: 59vh;
}
.carousel-container {
  width: 100%;
}

.contactus_parallax {
  background-image: url("../../assests/images/background/parallax_feature1.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  height: 90vh;
  display: grid;
  place-items: center;
  align-items: center;

}

.bg_men {
  background-image: url("../../assests/images/background/seafarers.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  height: 100%;
  width: 100%;
}

/* .justify-content {
  display: grid;
  place-items: start;
  justify-content: start;
} */
.custom_input:focus {
  outline: none;
  border: 1px solid #ffa415 !important;
}
.custom_input:hover {
  border: 1px solid #ffa415 !important;
}
.bg_form {
  background-color: #222222a4;
  border-radius: 8px;
}
button.video-react-button.video-react-big-play-button.video-react-big-play-button-left {
  display: none;
}





@media screen and (max-width: 768px) {
  .feature__card {
    height: 400px !important;
  }
}

.border-bottom {
  border-color: none;
  text-decoration: none;
}
.parallax_padding {
  padding: 5% 0 10px 0;
}

@media screen and (max-width: 768px) {
.contactus_parallax{height: 100%;}
.contactus_parallax img{margin-bottom:18px;}
.girl1{margin-bottom: 45px;}
.girl2{margin-bottom: 205px;}
.girl3{margin-bottom:-9px;}  
.meet{margin-bottom: 18px;}
.team2{margin-bottom:15px}
.team1{margin-bottom:16px}
.our
{ margin-left: 15px !important;
text-justify: center !important;}
.book{text-align: center;
font-size: large !important;}
.img{height: 60px;}
.unm{margin-right: -22px;}
.wall{margin-bottom: 20px;}
.rounded_image{width: 100% !important;
margin-right: -25px  !important;}
.hour{margin-bottom:-45px;}
.hotell{width: 50% !important;
}
.down{ display: none ;}
.unlock{margin-top: 50px;
font-size: x-large !important;}
.amaz{margin-top: -100px;}
 }

 @media screen and (min-width: 1280px) and (min-height:800px) {
  .contactus_parallax3{height:50vh;}}