.accordion_heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background-color: #33333314; */
  padding: 2px;
}
.accordion {
  transition: 0.5s all;
  cursor: pointer;
}
.accordion_body {
  transition: 0.5s all;
}
.accordion_heading {
  transition: all 0.5s;
}
.accordion_question{
    font-size: 16px;
}

