.bg_testi {
    background-image: url("../../assests/images/background/testimonials.jpg");
    height: 60vh;
    max-width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: grid;
    place-items: center;
    justify-content: center;
  }

  .testimonials{
    box-shadow: 5px 0px 10px 2px #eee;
    background-color:ghostwhite;
    height: 100%;
}
.image{

 border-radius: 50%;
}
  @media screen and (max-width: 768px) {
    .bg_testi{height: 275px;}
    .real{margin-left: 10px;}
    .test{margin-top: 40px;}
    .test{font-size: xx-large !important;}
    .real{font-size:20px !important;}
    .test{margin-left:12px}
  }
  