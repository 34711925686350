/* .bg_ocean{
    background-image: url('../../assests/images/background/strip.jpg');
    height: 60vh;
    max-width:100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: grid;
    place-items: center;
    justify-content: center;
    
}    */
.ocean_head{
    font-size: 77px;
} 
.bg_meen {
    background-image: url("../../assests/images/background/seafarers.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    height: 100%;
  }
  
  @media screen and (max-width: 768px) {
.strav{ margin-bottom: -12% !important;}
 }