.f-45 {
  font-size: 45px;
}
.f-35 {
  font-size: 35px;
}
.f-16 {
  font-size: 22px;
  text-align: center;
  margin-left:-48px ;
}
.f-20 {
  font-size: 20px;
}
.f-80 {
  font-size: 80px;
}
.f-22 {
  font-size: 22px;
}
.mt-6{
  margin-top: 6rem;
}
.f-15 {
  font-size: 15px;
}
.f-55{
  font-size: 55px;
}
.custom_btn:hover {
  background-color: transparent !important;
  color: #000 !important;
}
.text-primary {
  color: #e77000 !important;
}
.text-secondary {
  color: #013251 !important;
}
.text-alternative {
  color: #00d4ff !important;
}
.ls-base {
  letter-spacing: 5px;
}
.mt-7 {
  margin-top: 7rem;
}
.rotateicon {
  transform: scaleX(-1);
}
.ls-6 {
  letter-spacing: 6px;
}
.ls-1{
letter-spacing: 1px;
  }

.f-60 {
  font-size: 60px;
}
.f-30 {
  font-size: 30px;
}
.f-17{
  font-size: 17px;
}
.f-13{
  font-size:13px ;
}

.bg-white {
  background-color: white;
}
.pointer {
  cursor: pointer;
}
.inherit {
  color: inherit;
}
.inherit:hover {
  color: inherit;
}
.border_dashed {
  border-right: 2px dashed #000;
}
.mt-40 {
  margin-top: 40px;
}

::-webkit-scrollbar {
  width: 0px;
}
.text-justify {
  text-align: justify;
}
.text-left{
  text-align: left;
}
