.bookus_card {
  box-shadow: 0px 0px 2px 2px #eee;
  border-top: 1px solid transparent;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  border-bottom: 5px solid transparent;
  border-radius: 8px;
  transition: all 0.5s;
  height: 250px;
  padding: 10px;
  width: 90%;
}
.bookus_card:hover {
  border-bottom: 5px solid #013251;
  border-top: 1px solid #e77000;
  border-left: 1px solid #e77000;
  border-right: 1px solid #e77000;
  transform: translateY(-20px);
}
li.react-multi-carousel-item.react-multi-carousel-item--active{
  width: 384px !important;
}
