.bg_contact {
  background-image: url("../../assests/images/background/contact.jpg");
  height: 60vh;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: grid;
  place-items: center;
  justify-content: center;
}
.contactus_card {
  border: 1px solid transparent;
  /* box-shadow: 0px 0px 2px 2px #eee; */
}
.form_bg{
  background-color: #2222223a;
}

.rounded_card {

  border: 1px solid #ffffff;
  box-shadow: 0px 0px 14px 3px #eee;
}

.rounded_card:hover {
  border: 1px solid #e77000;
}

.rounded_card1 {

  border: 2px solid #ffffff;
  box-shadow: 0px 0px 4px 4px #eee;
}

@media screen and (max-width: 768px) {
.bg_contact{height: 258px;}
.getin{font-size:x-large !important;
}
.getin{text-align: center !important;}
.lets{font-size: 45px !important;}
}