.navbar_main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  width: 100%;
  z-index: 999;
  background-color: transparent;
}
.navbar_link {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.logo{
  width: 100px;
}

.link {
  text-decoration: none;
  color: #ffffff;

  
}

.link.active {
  color: #00d4ff;
  text-decoration: none;
  

}
.link:hover {
  color: #00d4ff;
  
  text-decoration: none;
}
.links_mobile{
  color: #222222;
  text-decoration: none;
}
.links_mobile.active {
  color: white;
  text-decoration: underline;
}
.links_mobile:hover {
  color: white;
  text-decoration: underline;
}
.menubar {
  display: none;
}
.links_mobile {
  display: grid;
  flex-direction: column;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .navbar_link {
    display: none;
  }
  .menubar {
    display: block;
  }
  .logo{
    width: 60px;

  }
  .nav_bar{
    position: absolute;
    background:rgba(128, 128, 128, 0.979);
    width: 100%;
    top: 67px;
    z-index: 999;
    font-size: 18px;
  }
}
